import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Slowly autumn is coming and the bike and race season comes to an end.
The fifth and last stop of the
`}<a parentName="p" {...{
        "href": "http://www.trailtrophy.eu/tt-kronplatz.html"
      }}>{`Trail Trophy Enduro Race Series`}</a>{`
takes place on the 14.-16.09.2018 at the Kronplatz in South Tirol, Italy. You
can now win two tickets to the race!`}</p>
    <br />
    <p><strong parentName="p">{`How to win?`}</strong>{` Like our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook page`}</a>{`
and comment the post (about the draw). The prize is a full three day start
package including the start number, timing, lift and shuttles and lunch and
dinner at the race. The winners are drawn on Sunday evening the 2nd of
September on Facebook.`}</p>
    <br />
    <p>{`See the trails of the Kronplatz below or check out more about the destination
on `}<a parentName="p" {...{
        "href": "https://www.trailguide.no/destination/italy/kronplatz"
      }}>{`Trailguide`}</a></p>
    <TrailguidePlugin content="lat=46.7493&lng=11.9614&coverage=5" mdxType="TrailguidePlugin" />
    <p>{`Good luck for the drawing and we are looking forward to see you at the Race!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      